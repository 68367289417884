import React from "react";
import useClasses from "hooks/useClasses";
import Table from "components/Shared/CustomTable/Table/Table";
import Button from "components/Shared/CustomButtons/Button";
import Modal from "components/Shared/CustomModal/CustomModal";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import PasswordIcon from "@mui/icons-material/Password";
import VerifiedIcon from "@mui/icons-material/VerifiedUser";
import BlockedIcon from "@mui/icons-material/Block";
import CreateNewUser from "./CreateNewUser";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  createModalHeader: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#fff",
    padding: "0 10px",
    borderRadius: "15px 15px 0 0",
  },
  createModalTitle: {
    fontWeight: 900,
  },
  createModalBody: {
    padding: "15px",
    maxHeight: "450px",
    overflowY: "scroll",
  },
});

export default function Branches({
  rows,
  totalRows,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  searchValue,
  searchHandler,
  selectedSearchBy,
  handleSearchByChange,

  deleteUser,
  setupEmailUser,
  changeStatusUser,
  resetPasswordUser,
  createUser,
  branches,
}) {
  const classes = useClasses(styles);
  const [selected, setSelected] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [openCreateModal, setOpenCreateModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleDelete = (id) => {
    if (id) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="warning"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to delete this user ? </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button color="danger" size="lg" onClick={() => deleteUser(id)}>
              Delete
            </Button>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    } else if (selected.length !== 0) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="warning"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to delete those users ? </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              color="danger"
              size="lg"
              onClick={() => deleteUser(selected)}
            >
              Delete
            </Button>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    }
    setOpenModal(true);
  };
  const handleEmailSetup = (id) => {
    if (id) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="warning"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to setup email for this user ? </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              color="primary"
              size="lg"
              onClick={() => setupEmailUser(id)}
            >
              Send Email
            </Button>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    } else if (selected.length !== 0) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="warning"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to setup email for those users ? </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              color="primary"
              size="lg"
              onClick={() => setupEmailUser(selected)}
            >
              Send Email
            </Button>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    }
    setOpenModal(true);
  };
  const handleChangeStatus = (id) => {
    if (id) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="warning"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to change status for this user ? </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              color="primary"
              size="lg"
              onClick={() => changeStatusUser(id)}
            >
              Change status
            </Button>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    } else if (selected.length !== 0) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="warning"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to change status for those users ? </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              color="primary"
              size="lg"
              onClick={() => changeStatusUser(selected)}
            >
              Change status
            </Button>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    }
    setOpenModal(true);
  };
  const handleResetPassword = (id) => {
    if (id) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="warning"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to reset password for this user ? </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              color="primary"
              size="lg"
              onClick={() => resetPasswordUser(id)}
            >
              Reset password
            </Button>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    } else if (selected.length !== 0) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="warning"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to reset password for those users ? </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              color="primary"
              size="lg"
              onClick={() => resetPasswordUser(selected)}
            >
              Reset password
            </Button>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    }
    setOpenModal(true);
  };

  const selectedToolbarButtons = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <Button color="danger" aria-label="delete" onClick={() => handleDelete()}>
        Delete Selected
      </Button> */}
      <Button
        color="primary"
        aria-label="Change status"
        onClick={() => handleChangeStatus()}
        style={{ marginLeft: "10px" }}
      >
        Change Status Selected
      </Button>
      <Button
        color="primary"
        aria-label="setup email"
        onClick={() => handleEmailSetup()}
        style={{ marginLeft: "10px" }}
      >
        Setup Email Selected
      </Button>
      <Button
        color="primary"
        aria-label="reset password"
        onClick={() => handleResetPassword()}
        style={{ marginLeft: "10px" }}
      >
        Reset Password Selected
      </Button>
    </div>
  );

  const toolbarButtons = (
    <div>
      <Button color="primary" onClick={() => setOpenCreateModal(true)}>
        Add new Branch User
      </Button>
    </div>
  );

  const searchByOptions = [
    {
      value: "display_name",
      label: "Name",
    },
    {
      value: "email",
      label: "Email",
    },
    {
      value: "branch_name",
      label: "Branch Name",
    },
  ];

  const headCells = [
    {
      id: "display_name",
      sortable: true,
      label: "Name",
    },
    { id: "email", sortable: true, label: "Email" },
    { id: "role", sortable: true, label: "Role" },
    {
      id: "branch_name",
      sortable: true,
      label: "Branch Name",
    },
    { id: "is_active", sortable: true, label: "User Active" },
    { id: "is_email_verified", sortable: true, label: "Email Verified" },
    { id: "options", sortable: false, label: "Options" },
  ];

  const tableCells = (
    <>
      {rows.length !== 0 ? (
        rows.map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow key={row.id} hover>
              <TableCell padding="checkbox">
                <Checkbox
                  onClick={(event) => handleClick(event, row.id)}
                  checked={isItemSelected}
                  inputProps={{ "aria-labelledby": labelId }}
                  color="primary"
                />
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.display_name}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.email}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.role}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.branch_name}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.is_active === 1 ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <VerifiedIcon color="success" />
                      </div>
                      <div style={{ height: "30px" }}>
                        <span
                          style={{ fontSize: "14px", verticalAlign: "middle" }}
                        >
                          User Activated
                        </span>
                      </div>
                    </div>
                    <Button
                      color="danger"
                      aria-label="changeStatus"
                      onClick={() => handleChangeStatus([row.id])}
                      style={{ borderRadius: "15px", marginTop: "5px" }}
                    >
                      <span style={{ fontSize: "12px" }}>Disactivate User</span>
                    </Button>
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <BlockedIcon color="error" />
                      </div>
                      <div style={{ height: "30px" }}>
                        <span
                          style={{ fontSize: "14px", verticalAlign: "middle" }}
                        >
                          {" "}
                          User Disactivated
                        </span>
                      </div>
                    </div>

                    <Button
                      color="success"
                      aria-label="changeStatus"
                      onClick={() => handleChangeStatus([row.id])}
                      style={{ borderRadius: "15px", marginTop: "5px" }}
                    >
                      <span style={{ fontSize: "12px" }}>activate user</span>
                    </Button>
                  </div>
                )}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.is_email_verified === 1 ? (
                  <Tooltip title="email verified">
                    <i className="fas fa-user-check"></i>
                  </Tooltip>
                ) : (
                  <Tooltip title="email not verified">
                    <i className="fas fa-user-times"></i>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell component="th" padding="normal" align="center">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <Tooltip title="Delete Client">
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDelete([row.id])}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip> */}
                  <Tooltip title="Setup Email">
                    <IconButton
                      aria-label="setup email"
                      onClick={() => handleEmailSetup([row.id])}
                    >
                      <ForwardToInboxIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Reset Password">
                    <IconButton
                      aria-label="Reset password"
                      onClick={() => handleResetPassword([row.id])}
                    >
                      <PasswordIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={12} align="center">
            No Branch Users
          </TableCell>
        </TableRow>
      )}
    </>
  );
  return (
    <React.Fragment>
      <Table
        rows={rows}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        searchHandler={searchHandler}
        searchValue={searchValue}
        selectedSearchBy={selectedSearchBy}
        handleSearchByChange={handleSearchByChange}
        tableCells={tableCells}
        selected={selected}
        setSelected={setSelected}
        headCells={headCells}
        isSelectable={true}
        selectedToolbarButtons={selectedToolbarButtons}
        toolbarButtons={toolbarButtons}
        showSearchBy={true}
        searchByOptions={searchByOptions}
        showFilter={false}
        filterTitle={""}
        selectedFilter={null}
        handleFilterChange={null}
        filterOptions={null}
        handleFilterReset={null}
      />
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal>
      <Modal
        open={openCreateModal}
        handleClose={() => setOpenCreateModal(false)}
        customStyle={{ width: "100%", maxWidth: "500px", padding: 0 }}
      >
        <div className={classes.createModalHeader}>
          <span className={classes.createModalTitle}>Add New Branche User</span>
          <IconButton
            aria-label="delete"
            onClick={() => setOpenCreateModal(false)}
          >
            <CloseIcon style={{ color: "#fff" }} />
          </IconButton>
        </div>
        <div className={classes.createModalBody}>
          <CreateNewUser branches={branches} createUser={createUser} />
        </div>
      </Modal>
    </React.Fragment>
  );
}
