const styles = (theme) => ({
  form: {
    marginBottom: "20px",
    padding: "10px",
  },
  label: {
    fontWeight: "bold",
    marginBottom: "10px",
    color: theme.palette.primary.mainColor + "!important",
  },
  labelError: {
    fontWeight: "bold",
    marginBottom: "10px",
    color: "red",
  },
  selectInput: {
    width: "100%",
  },
  customCheckbox: {
    color: theme.palette.primary.main,
    "&:checked": {
      color: theme.palette.primary.main,
    },
  },
  customRadio: {
    color: theme.palette.primary.main,
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
  chip: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  catBranchForm: {
    boxSizing: "border-box",
    padding: "10px",
    width: "100%",
  },
  catBranchContainer: {
    // margin: "10px auto !important ",
    width: "100%",
  },
  selectedCategoryContainer: {
    backgroundColor: theme.palette.background.default,
    marginBottom: "10px!important",
    padding: "10px!important",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
  selectedCategory: {
    flex: 1,
  },
  selectedBranchContainer: {
    backgroundColor: theme.palette.background.default,
    marginBottom: "10px!important",
    padding: "10px!important",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
  selectedBranch: {
    flex: 1,
  },
});

export default styles;
