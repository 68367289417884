import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";

// Agents or Branch users

export const getUsersBranches = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({ type: actionsType.GET_USERS_BRANCHES, payload: null });
    const { data } = await api.getUsersBranches(formData);
    dispatch({ type: actionsType.GET_USERS_BRANCHES, payload: data });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const createNewUserBranch =
  (newUser, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.createUsersBranches(newUser);
      dispatch({ type: actionsType.GET_USERS_BRANCHES, payload: null });
      const { data } = await api.getUsersBranches(formData);
      dispatch({ type: actionsType.GET_USERS_BRANCHES, payload: data });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "New Branch user created successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const deleteUserBranch =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.deleteUsersBranches({ ids: idsArray });
      dispatch({ type: actionsType.GET_USERS_BRANCHES, payload: null });
      const { data } = await api.getUsersBranches(formData);
      dispatch({ type: actionsType.GET_USERS_BRANCHES, payload: data });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: { type: "success", text: "user Branch deleted successfully" },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const changeStatusUserBranch =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.changeStatusUsersBranches({ ids: idsArray });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "status changed Successfully",
        },
      });
      dispatch(getUsersBranches(formData, history));
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
export const resendEmailUsersBranch =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.resendEmailUsersBranches({ ids: idsArray });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "email resend Successfully",
        },
      });
      dispatch(getUsersBranches(formData, history));
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
export const resetPasswordUsersBranch =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.resetPasswordUsersBranches({ ids: idsArray });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "reset password email sent Successfully",
        },
      });
      dispatch(getUsersBranches(formData, history));
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const getAllBranches = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({ type: actionsType.GET_ALL_BRANCHES, payload: null });
    const { data } = await api.getAllBranches();
    dispatch({ type: actionsType.GET_ALL_BRANCHES, payload: data });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
