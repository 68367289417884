import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import useClasses from "hooks/useClasses";

import { Grid, InputLabel } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";

import styles from "./styles";

import CustomSwitch from "components/Shared/CustomSwitch/CustomSwitch";
import Button from "components/Shared/CustomButtons/Button";
import { getBranchesByCategories } from "store/actions/vouchers";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";

const AssignCatBranches = ({
  voucher,
  allCategories,
  allBranches,
  assignHandler,
}) => {
  const [isSelectedCategories, setIsSelectedCategories] = useState(false);
  const [isSelectedBranches, setIsSelectedBranches] = useState(false);
  const [isAllCategories, setIsAllCategories] = useState(0);
  const [categoryIds, setCategoryIds] = useState([]);
  const [isAllBranches, setIsAllBranches] = useState(0);
  const [branchIds, setBranchIds] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const branchesCategories = useSelector(
    (state) => state.vouchers.branchesCategories
  );
  const classes = useClasses(styles);

  const findName = (array, id) => {
    return array.map((a) => {
      if (a.id === id) {
        return a.name;
      }
    });
  };

  const getIdsFromArray = (array) => {
    let idsArray = [];
    array.map((a) => idsArray.push(a.id));
    return idsArray;
  };

  useEffect(() => {
    if (voucher) {
      setIsAllCategories(voucher.is_all_categories);
      setIsSelectedCategories(true);
      if (voucher.is_all_categories === 1) {
        setCategoryIds(getIdsFromArray(allCategories));
        dispatch(
          getBranchesByCategories(
            { category_ids: getIdsFromArray(allCategories) },
            history
          )
        );
      } else {
        setCategoryIds(getIdsFromArray(voucher.categories));
        dispatch(
          getBranchesByCategories(
            { category_ids: getIdsFromArray(voucher.categories) },
            history
          )
        );
      }
      setIsAllBranches(voucher.is_all_branches);
      setIsSelectedBranches(true);
      if (voucher.is_all_branches === 1) {
        setBranchIds(getIdsFromArray(allBranches));
      } else {
        setBranchIds(getIdsFromArray(voucher.branches));
      }
    }
  }, [voucher]);

  const handleSubmit = () => {
    const result = {
      voucher_id: voucher.id,
      is_all_categories: isAllCategories,
      category_ids: categoryIds,
      is_all_branches: isAllBranches,
      branch_ids: branchIds,
    };
    assignHandler(result);
  };
  const selectCategoriesHandler = () => {
    setBranchIds([]);
    setIsSelectedBranches(false);
    setIsAllBranches(0);
    if (isAllCategories === 1) {
      setCategoryIds(getIdsFromArray(allCategories));
      dispatch(
        getBranchesByCategories(
          { category_ids: getIdsFromArray(allCategories) },
          history
        )
      );
    } else {
      dispatch(getBranchesByCategories({ category_ids: categoryIds }, history));
    }
    setIsSelectedCategories(true);
  };
  const editCategoriesHandler = () => {
    setIsSelectedCategories(false);
  };
  const selectBranchesHandler = () => {
    if (isAllBranches === 1) {
      setBranchIds(getIdsFromArray(allBranches));
    }
    setIsSelectedBranches(true);
  };
  const editBranchesHandler = () => {
    setIsSelectedBranches(false);
  };

  return (
    <div className={classes.catBranchForm}>
      <Grid container className={classes.catBranchContainer}>
        {!isSelectedCategories ? (
          <>
            {/* isAllCategories */}
            <Grid item xs={12}>
              <CustomSwitch
                name="isAllCategories"
                value={isAllCategories}
                onChangeHandler={(e) => setIsAllCategories(e.target.value)}
                title="Is All Categories"
              />
            </Grid>
            {/* Categories */}
            {isAllCategories !== 1 && (
              <Grid item xs={12}>
                <InputLabel className={classes.label}>Categories:</InputLabel>
                <Select
                  id="categoryIds"
                  name="categoryIds"
                  label="Categories"
                  className={classes.selectInput}
                  value={categoryIds}
                  onChange={(e) => setCategoryIds(e.target.value)}
                  multiple={true}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={findName(allCategories, value)}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {allCategories.map((cat) => (
                    <MenuItem key={cat.id} value={cat.id}>
                      <Checkbox
                        checked={categoryIds.indexOf(cat.id) > -1}
                        color="default"
                        className={classes.customCheckbox}
                      />
                      <ListItemText primary={cat.name} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
            <Grid item xs={12} style={{ margin: "20px auto" }}>
              <Button
                color="primary"
                fullWidth
                variant="contained"
                className={classes.submit}
                onClick={selectCategoriesHandler}
              >
                Select Categories
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} className={classes.selectedCategoryContainer}>
              <div className={classes.selectedCategory}>
                <InputLabel className={classes.label}>
                  Selected Categories:
                </InputLabel>
                {isAllCategories !== 1 ? (
                  <div className={classes.chips}>
                    {categoryIds.map((value) => (
                      <Chip
                        key={value}
                        label={findName(allCategories, value)}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                ) : (
                  <div>
                    <Chip
                      label={"All Categories Selected"}
                      className={classes.chip}
                    />
                  </div>
                )}
              </div>
              <div>
                <Tooltip title="Edit Categories">
                  <IconButton aria-label="edit" onClick={editCategoriesHandler}>
                    <EditIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>
            {!isSelectedBranches ? (
              <>
                {branchesCategories && (
                  <>
                    {/* isAllBranches */}
                    <Grid item xs={12}>
                      <CustomSwitch
                        name="isAllBranches"
                        value={isAllBranches}
                        onChangeHandler={(e) =>
                          setIsAllBranches(e.target.value)
                        }
                        title="Is All Branches"
                      />
                    </Grid>
                    {/* branches */}
                    {isAllBranches !== 1 && (
                      <Grid item xs={12}>
                        <InputLabel className={classes.label}>
                          Branches:
                        </InputLabel>
                        <Select
                          id="branchIds"
                          name="branchIds"
                          label="Branches"
                          className={classes.selectInput}
                          value={branchIds}
                          onChange={(e) => setBranchIds(e.target.value)}
                          multiple={true}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={findName(allBranches, value)}
                                  className={classes.chip}
                                />
                              ))}
                            </div>
                          )}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {branchesCategories.map((br) => (
                            <MenuItem key={br.id} value={br.id}>
                              <Checkbox
                                checked={branchIds.indexOf(br.id) > -1}
                                color="default"
                                className={classes.customCheckbox}
                              />
                              <ListItemText primary={br.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    )}
                    <Grid item xs={12} style={{ margin: "20px auto" }}>
                      <Button
                        color="primary"
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                        onClick={selectBranchesHandler}
                      >
                        Select Branches
                      </Button>
                    </Grid>
                  </>
                )}
              </>
            ) : (
              <>
                <Grid item xs={12} className={classes.selectedBranchContainer}>
                  <div className={classes.selectedBranch}>
                    <InputLabel className={classes.label}>
                      Selected Branches:
                    </InputLabel>
                    {isAllBranches !== 1 ? (
                      <div className={classes.chips}>
                        {branchIds.map((value) => (
                          <Chip
                            key={value}
                            label={findName(allBranches, value)}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    ) : (
                      <div className={classes.chips}>
                        <Chip
                          label="All Branches Selected"
                          className={classes.chip}
                        />{" "}
                      </div>
                    )}
                  </div>
                  <div>
                    <Tooltip title="Edit Branch">
                      <IconButton
                        aria-label="edit"
                        onClick={editBranchesHandler}
                      >
                        <EditIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
              </>
            )}
          </>
        )}

        {isSelectedCategories && isSelectedBranches && (
          <Grid item xs={12} style={{ margin: "20px auto" }}>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              className={classes.submit}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default AssignCatBranches;
